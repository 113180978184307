<template>
  <div>
    <div
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      v-if="loading"
    >
      <t-loader extraClass="border-primary-900 w-12 h-12" />
    </div>

    <div v-if="!loading" class="pt-2">
      <div
        class="w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-2 gap-y-4 mb-4"
      >
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Subcategorías',
              quantity: data.counters.subcategories.total,
              icon: 'chess',
              change: data.counters.subcategories.actives,
              changeVar: 'subcategorías activas'
            }"
            bgColor="bg-yellow-1100"
          />
        </div>
      </div>

      <div class="flex flex-col md:flex-row align-center justify-between my-4">
        <div class="flex flex-col md:flex-row align-center">
          <div class="my-auto mx-2">
            <searchbar
              placeholder="Buscar"
              v-model="query"
              @changeQuery="changeQuery"
            ></searchbar>
          </div>
        </div>

        <t-button
          class="my-2 md:my-auto mr-2 h-fit py-2"
          @click="$router.push('/subcategorias/crear')"
        >
          Crear subcategoría
        </t-button>
      </div>

      <div class="w-full">
        <basic-table
          ref="list-table"
          module="subcategory"
          :headers="headers"
          :url="'subcategorias'"
          :data="subcategories"
          :route="'/subcategory'"
          :tableName="'SUBCATEGORÍAS'"
          :parameters="parameters"
          :isMaster="true"
          @deactivate="deactivate"
          @activate="activate"
          @erase="erase"
        />
      </div>
    </div>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import StatCard from "@/components/Statistics/Card.vue"
import Searchbar from "@/components/Searchbar"
export default {
  components: {
    StatCard,
    Searchbar
  },
  data() {
    return {
      loading: true,
      submitLoader: false,
      headers: ["Nombre", "Registrado el", "Acciones"],
      data: {
        counters: {
          tournaments: {},
          academies: {},
          players: {},
          coaches: {},
          notices: {},
          games: {},
          categories: {},
          subcategories: {},
          titles: {}
        }
      },
      query: "",
      parameters: {}
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    },
    subcategories() {
      return this.$store.getters["global/getItems"](
        "subcategory",
        "elements"
      ).map((el) => ({
        id: el.id,
        name: el.name,
        datetime: el.created_at,
        active: el.active
      }))
    }
  },
  methods: {
    changeQuery(value) {
      if (this.$refs["list-table"]) this.$refs["list-table"].changeQuery(value)
    },
    getCounters() {
      this.$store
        .dispatch("global/getItems", {
          route: "user/admin/counters",
          noPaginate: true
        })
        .then((response) => {
          this.data = response.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    deactivate() {
      this.data.counters.subcategories.actives--
    },
    activate() {
      this.data.counters.subcategories.actives++
    },
    erase() {
      this.data.counters.subcategories.total--
      this.data.counters.subcategories.actives--
    }
  },
  created() {
    this.getCounters()
  }
}
</script>
